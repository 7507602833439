
import TooltipHover from '../TooltipHover/index.vue'
import WithdrawBtn from './WithdrawBtn.vue'
import SellBtn from './SellBtn.vue'
import TryAgainBtn from './TryAgainBtn.vue'
import { computed, defineComponent, onDeactivated, onMounted, PropType, reactive, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	props: {
		status: {
			type: String,
			required: true,
			validator: (status: string) => [
				'IDLE',
				'BUYING',
				'SELLING',
				'WAITING_SELLER',
				'READY',
				'COMPLETED',
				'ERROR'
			].includes(status)
		},
		timerEndsAt: {
			type: Object as PropType<Date>
		},
		error: {
			type: String
		},
		withdrawBtn: {
			type: Boolean,
			default: true
		}
	},
	components: {
		TooltipHover,
		WithdrawBtn,
		SellBtn,
		TryAgainBtn
	},
	emits: ['sell', 'withdraw', 'offer'],
	setup(props, { emit }) {
		const { t } = useI18n()
		const state = reactive<{
			interval: any
			timer: string
			mouseOver: boolean
		}>({
			interval: null,
			timer: '05:00',
			mouseOver: false
		})

		onMounted(() => {
			state.interval = setInterval(() => {
				if (!props.timerEndsAt) {
					return
				}

				let ms = Math.floor((props.timerEndsAt.getTime() - Date.now()) / 1000)

				if (ms < 0) {
					ms = 0
				}

				const m = Math.floor(ms / 60)
				const s = ms % 60

				state.timer = `${m < 10 ? ('0' + m) : m}:${s < 10 ? ('0' + s) : s}`
			}, 1000)
		})

		onDeactivated(() => {
			if (state.interval) {
				clearInterval(state.interval)
			}
		})

		const allowClick = computed(() => {
			return state.mouseOver
		})

		const hover = computed(() => {
			return props.status !== 'IDLE'
		})

		const danger = computed(() => {
			return props.status === 'ERROR'
		})

		const completed = computed(() => {
			return ['COMPLETED', 'SOLD'].includes(props.status)
		})

		const loadingText = computed(() => {
			if (props.status === 'BUYING') {
				return t('InventoryItemOverlay.buying')
			}

			return t('InventoryItemOverlay.selling')
		})

		const errorNormalized = computed(() => {
			const text = t(`errors.${props.error}`)

			if (text !== `errors.${props.error}`) {
				return text
			}

			return props.error
		})

		// mouse handlers
		const sell = () => {
			emit('sell')
		}

		const withdraw = () => {
			emit('withdraw')
		}

		const offer = () => {
			emit('offer')
		}

		const mouse = (over: boolean) => {
			setTimeout(() => {
				state.mouseOver = over
			}, 250)
		}

		return {
			...toRefs(state),
			t,
			allowClick,
			hover,
			danger,
			completed,
			loadingText,
			errorNormalized,
			sell,
			withdraw,
			offer,
			mouse
		}
	}
})
