<template>
	<router-link to="/" custom v-slot="{ navigate }">
		<BaseButton class="base-button-wrap success b-10" @click="navigate">
			<template #left>
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M17.76 20.64H6.24001C5.17921 20.64 4.32001 19.7808 4.32001 18.72V8.64001C4.32001 7.84513 4.96513 7.20001 5.76001 7.20001H18.24C19.0349 7.20001 19.68 7.84513 19.68 8.64001V18.72C19.68 19.7808 18.8208 20.64 17.76 20.64Z"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M15.839 10.368C15.7065 10.368 15.599 10.4755 15.6 10.608C15.6 10.7405 15.7075 10.848 15.84 10.848C15.9725 10.848 16.08 10.7405 16.08 10.608C16.08 10.4755 15.9725 10.368 15.839 10.368"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M8.15904 10.368C8.02656 10.368 7.91904 10.4755 7.92 10.608C7.92 10.7405 8.02752 10.848 8.16 10.848C8.29248 10.848 8.4 10.7405 8.4 10.608C8.4 10.4755 8.29248 10.368 8.15904 10.368"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M8.16003 7.20002V6.96002V6.96002C8.16003 4.97186 9.77187 3.36002 11.76 3.36002H12.24C14.2282 3.36002 15.84 4.97186 15.84 6.96002V6.96002V7.20002"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			</template>
			{{ t('ButtonShop.text') }}
		</BaseButton>
	</router-link>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n';
import BaseButton from '../BaseButton/index.vue'

export default defineComponent({
	components: {
		BaseButton
	},
	setup() {
		const { t } = useI18n()

		return {
			t
		}
	}
})
</script>
<style scoped>
.base-button-wrap {
	height: 44px;
}
</style>
