<template>
	<BaseButton
		class="content-btn white xs b-4 uc"
	>
		<template #left>
			<svg
				width="17"
				height="17"
				viewBox="0 0 17 17"
				fill="none"
			>
				<path
					d="M4.5 11.1667H5.83333"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M9.83333 13.8333H3.91667C3.134 13.8333 2.5 13.1993 2.5 12.4167V5.73667C2.5 5.54533 2.53867 5.35533 2.614 5.18L3.392 3.36C3.61467 2.83867 4.12733 2.5 4.69467 2.5H11.6393C12.2067 2.5 12.7187 2.83867 12.942 3.36L13.72 5.18C13.7947 5.356 13.8333 5.54533 13.8333 5.73667V8.5"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M8.16683 2.5V5.46"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M13.8048 5.46004H2.52881"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M11.8333 14.5C9.99267 14.5 8.5 13.0074 8.5 11.1667C8.5 9.32604 9.99267 7.83337 11.8333 7.83337C13.6747 7.83337 15.1667 9.32604 15.1667 11.1667C15.1667 13.0074 13.6747 14.5 11.8333 14.5"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M10.5 11.1667H13.1667"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M11.8333 12.5V9.83337"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M4.5 11.1667H5.83333"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</template>
		<slot />
	</BaseButton>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import BaseButton from '../BaseButton/index.vue'

export default defineComponent({
	components: {
		BaseButton
	}
})
</script>
<style scoped>
.content-btn {
	height: 25px;
	width: 100%;
	margin-top: 5px;
}
</style>
