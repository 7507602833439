
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n';
import BaseButton from '../BaseButton/index.vue'

export default defineComponent({
	components: {
		BaseButton
	},
	setup() {
		const { t } = useI18n()

		return {
			t
		}
	}
})
