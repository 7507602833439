<template>
	<div
		class="item-overlay"
		:class="{
			hover,
			danger,
			completed,
			allowClick
		}"
		@mouseenter="mouse(true)"
		@mouseleave="mouse(false)"
	>
		<div class="overlay-content controls h-100" v-if="status === 'IDLE'">
			<SellBtn class="sell-btn" @click="sell" />
			<WithdrawBtn
				class="withdraw-btn"
				@click="withdraw"
				v-if="withdrawBtn"
			>{{ t('InventoryItemOverlay.withdraw') }}</WithdrawBtn>
		</div>
		<div class="overlay-content" v-if="['BUYING', 'SELLING'].includes(status)">
			<img :src="require('../../assets/icons/overlay_process.svg')" class="content-logo" />
			<div class="content-text">{{ loadingText }}</div>
		</div>
		<div class="overlay-content" v-if="status === 'WAITING_SELLER'">
			<div class="content-timer">{{ timer }}</div>
			<div class="content-text">{{ t('InventoryItemOverlay.waitingSeller') }}</div>
		</div>
		<div class="overlay-content h-100" v-if="status === 'READY'">
			<div class="content-timer ready">{{ timer }}</div>
			<WithdrawBtn class="take-btn" @click="offer">{{ t('InventoryItemOverlay.take') }}</WithdrawBtn>
		</div>
		<TooltipHover v-if="error && status === 'ERROR'" class="overlay-content">
			<template #text>{{ errorNormalized }}</template>
			<template #root>
				<img :src="require('../../assets/icons/danger.svg')" class="content-logo error" />
				<div class="content-text danger mt-5">{{ t('InventoryItemOverlay.error') }}</div>
				<SellBtn class="try-again-sell-btn" @click="sell" />
				<TryAgainBtn class="try-again-btn" @click="withdraw" v-if="withdrawBtn" />
			</template>
		</TooltipHover>
		<div class="overlay-content h-100" v-if="!error && status === 'ERROR'">
			<img :src="require('../../assets/icons/danger.svg')" class="content-logo error" />
			<div class="content-text danger mt-5">{{ t('InventoryItemOverlay.error') }}</div>
			<SellBtn class="try-again-sell-btn" @click="sell" />
			<TryAgainBtn class="try-again-btn" @click="withdraw" v-if="withdrawBtn" />
		</div>
		<div class="overlay-content" v-if="status === 'COMPLETED'">
			<img :src="require('../../assets/icons/withdraw.svg')" class="content-icon" />
			<div class="content-text warning mt-5">{{ t('InventoryItemOverlay.withdrawn') }}</div>
		</div>
		<div class="overlay-content" v-if="status === 'SOLD'">
			<img :src="require('../../assets/icons/overlay_process.svg')" class="content-icon" />
			<div class="content-text mt-5">{{ t('InventoryItemOverlay.sold') }}</div>
		</div>
	</div>
</template>
<script lang="ts">
import TooltipHover from '../TooltipHover/index.vue'
import WithdrawBtn from './WithdrawBtn.vue'
import SellBtn from './SellBtn.vue'
import TryAgainBtn from './TryAgainBtn.vue'
import { computed, defineComponent, onDeactivated, onMounted, PropType, reactive, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	props: {
		status: {
			type: String,
			required: true,
			validator: (status: string) => [
				'IDLE',
				'BUYING',
				'SELLING',
				'WAITING_SELLER',
				'READY',
				'COMPLETED',
				'ERROR'
			].includes(status)
		},
		timerEndsAt: {
			type: Object as PropType<Date>
		},
		error: {
			type: String
		},
		withdrawBtn: {
			type: Boolean,
			default: true
		}
	},
	components: {
		TooltipHover,
		WithdrawBtn,
		SellBtn,
		TryAgainBtn
	},
	emits: ['sell', 'withdraw', 'offer'],
	setup(props, { emit }) {
		const { t } = useI18n()
		const state = reactive<{
			interval: any
			timer: string
			mouseOver: boolean
		}>({
			interval: null,
			timer: '05:00',
			mouseOver: false
		})

		onMounted(() => {
			state.interval = setInterval(() => {
				if (!props.timerEndsAt) {
					return
				}

				let ms = Math.floor((props.timerEndsAt.getTime() - Date.now()) / 1000)

				if (ms < 0) {
					ms = 0
				}

				const m = Math.floor(ms / 60)
				const s = ms % 60

				state.timer = `${m < 10 ? ('0' + m) : m}:${s < 10 ? ('0' + s) : s}`
			}, 1000)
		})

		onDeactivated(() => {
			if (state.interval) {
				clearInterval(state.interval)
			}
		})

		const allowClick = computed(() => {
			return state.mouseOver
		})

		const hover = computed(() => {
			return props.status !== 'IDLE'
		})

		const danger = computed(() => {
			return props.status === 'ERROR'
		})

		const completed = computed(() => {
			return ['COMPLETED', 'SOLD'].includes(props.status)
		})

		const loadingText = computed(() => {
			if (props.status === 'BUYING') {
				return t('InventoryItemOverlay.buying')
			}

			return t('InventoryItemOverlay.selling')
		})

		const errorNormalized = computed(() => {
			const text = t(`errors.${props.error}`)

			if (text !== `errors.${props.error}`) {
				return text
			}

			return props.error
		})

		// mouse handlers
		const sell = () => {
			emit('sell')
		}

		const withdraw = () => {
			emit('withdraw')
		}

		const offer = () => {
			emit('offer')
		}

		const mouse = (over: boolean) => {
			setTimeout(() => {
				state.mouseOver = over
			}, 250)
		}

		return {
			...toRefs(state),
			t,
			allowClick,
			hover,
			danger,
			completed,
			loadingText,
			errorNormalized,
			sell,
			withdraw,
			offer,
			mouse
		}
	}
})
</script>
<style scoped>
.item-overlay {
	padding: 10px;
	display: flex;
	border-radius: 10px;
	align-items: center;
	justify-content: center;
	height: 100%;
	box-sizing: border-box;
}
.item-overlay:hover,
.item-overlay.hover {
	background: rgba(28, 29, 36, 0.8);
}

.item-overlay:hover:not(.completed),
.item-overlay.hover:not(.completed) {
	border: 1px solid #97ed4c;
}

.item-overlay:hover.danger:not(.completed),
.item-overlay.hover.danger:not(.completed) {
	border: 1px solid #ff6339;
}

.item-overlay:hover:not(.completed) .controls,
.item-overlay.hover:not(.completed) .controls {
	display: flex;
}

.overlay-content {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.overlay-content.h-100 {
	height: 100%;
}

.controls {
	display: none;
}

.content-timer {
	font-weight: normal;
	font-size: 30px;
	line-height: 42px;
	text-align: center;
	color: #fff;
}

.mobile .content-timer.ready {
	margin-top: 28px;
}

.content-timer.ready {
	margin-top: 38px;
}

.content-text {
	font-weight: 600;
	font-size: 12px;
	line-height: 17px;
	text-align: center;
	color: #ffffff;
	margin-top: -1px;
}

.content-text.danger {
	color: #ff6339;
}

.content-text.warning {
	color: #fe932a;
}

.content-text.success {
	color: #5ed82d;
}

.content-text.mt-5 {
	margin-top: 5px;
}

.content-logo {
	margin: 0 auto;
}

.content-logo.error {
	margin: 26px auto 0 auto;
}

.mobile .content-logo.error {
	margin: 0 auto;
}

.content-icon {
	margin: 0 auto;
	height: 36px;
	width: 36px;
}

.content-btn {
	height: 25px;
	width: 100%;
	margin-top: 5px;
	pointer-events: none;
}

.item-overlay.allowClick .content-btn {
	pointer-events: auto;
}

.sell-btn,
.try-again-sell-btn,
.take-btn {
	margin-top: auto;
}
/* 
.content-btn.primary {
	background: #4e7cff;
	margin-top: 5px;
}

.content-btn.primary:hover {
	background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.2) 0%,
			rgba(255, 255, 255, 0) 100%
		),
		#4e7cff;
}

.content-btn.primary.timer {
	margin-top: 11px;
}

.content-btn.success {
	background: #5ed82d;
}

.content-btn.success:hover {
	background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.2) 0%,
			rgba(255, 255, 255, 0) 100%
		),
		#5ed82d;
}

.content-btn.error {
	margin-top: 15px;
}

.content-btn.danger {
	background: #ff4b55;
	margin-top: 5px;
}

.content-btn.danger:hover {
	background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.2) 0%,
			rgba(255, 255, 255, 0) 100%
		),
		#ff4b55;
} */
</style>
