
import Item from '../../components/InventoryItem/index.vue'
import ItemOverlay from '../../components/InventoryItemOverlay/index.vue'
import Spinner from '../../components/Spinner/index.vue'
import ButtonShop from '../../components/ButtonShop/index.vue'
import { defineComponent, reactive, toRefs } from 'vue'
import { useUserID } from '@/composables/getAuthToken'
import { useSubscription } from '../../composables/useSubscription'
import { useAPI } from '@/composables/api'
import { useI18n } from 'vue-i18n'
import { useAlertStore } from '@/store/alert'

export default defineComponent({
	components: {
		Item,
		ItemOverlay,
		Spinner,
		ButtonShop
	},
	emits: ['tradelink-error'],
	setup(_, { emit }) {
		const { t } = useI18n()
		const api = useAPI()
		const alert = useAlertStore()
		const state = reactive({
			items: [] as any[],
			loading: true
		})

		const setItemMeta = (id: number, meta: any) => {
			for (let i = 0; i < state.items.length; i++) {
				if (state.items[i].id === id) {
					for (const key in meta) {
						state.items[i][key] = meta[key]
					}
					break
				}
			}
		}

		const setItemStatus = (id: number, status: any) => {
			setItemMeta(id, { status })
		}

		const getItemStatus = (withdraw: any) => {
			if (withdraw) {
				if (withdraw.status === 'PENDING') {
					return 'BUYING'
				} else if (withdraw.status === 'AWAITING_SELLER') {
					return 'WAITING_SELLER'
				} else if (withdraw.status === 'AWAITING_BUYER') {
					return 'READY'
				} else if (withdraw.status === 'COMPLETED') {
					return 'COMPLETED'
				} else if (withdraw.status === 'ERROR') {
					return 'ERROR'
				}
			}

			return 'IDLE'
		}

		const getTimer = (withdraw: any) => {
			if (withdraw) {
				if (withdraw.status === 'AWAITING_SELLER' && withdraw.sellerDeclinedAt) {
					return new Date(withdraw.sellerDeclinedAt)
				} else if (withdraw.status === 'AWAITING_BUYER' && withdraw.buyerDeclinedAt) {
					return new Date(withdraw.buyerDeclinedAt)
				}
			}

			return null
		}

		const offer = (id: number) => {
			for (let i = 0; i < state.items.length; i++) {
				if (state.items[i].id === id) {
					window.open(
						`https://steamcommunity.com/tradeoffer/${state.items[i].withdraw.steamTradeId}`,
						'_blank'
					)
					break
				}
			}
		}

		const userID = useUserID()

		if (userID) {
			useSubscription<any>(`withdraw#${userID}`, ({ data: withdraw, event }) => {
				const status = getItemStatus(withdraw)
				const timerEndsAt = getTimer(withdraw)

				setItemMeta(withdraw.inventoryId!, { status, withdraw, timerEndsAt })

				if (withdraw.status === 'AWAITING_BUYER') {
					alert.pushAlert({
						type: 'trade',
						text: t('AppFrame.withdrawAlert'),
						meta: { tradeID: withdraw.steamTradeId }
					})
				}
			})
		}

		const sell = async (id: number) => {
			setItemStatus(id, 'SELLING')

			try {
				await api({
					path: '/shop/sell',
					method: 'POST',
					body: {
						inventoryIds: [id]
					}
				})

				setItemStatus(id, 'SOLD')
			} catch (err) {
				setItemStatus(id, 'IDLE')
				alert.pushAlert({
					type: 'danger',
					text: `${t('Items.errors.sell')}: ${err.message}`
				})
			}
		}

		const withdraw = async (id: number) => {
			setItemStatus(id, 'BUYING')

			try {
				await api({
					path: '/withdrawals/create/inventory',
					method: 'POST',
					body: {
						inventoryIds: [id]
					}
				})
			} catch (err) {
				setItemStatus(id, 'IDLE')
				alert.pushAlert({
					type: 'danger',
					text: `${t('Items.errors.withdraw')}: ${err.message}`
				})

				if (err.key && err.key === 'errors.WITHDRAW.USER_TRADELINK_INVALID') {
					emit('tradelink-error')
				}
			}
		}

		const getInventory = async () => {
			try {
				const items: any[] = await api({
					path: '/inventory/current',
					method: 'POST',
					body: {
						bonuses: false,
						withdrawals: true
					}
				})

				state.items = items.map((item, i) => {
					item.status = getItemStatus(item.withdrawal)
					item.timerEndsAt = getTimer(item.withdrawal)
					item.item.priceUsd = item.buyPriceUsd

					return item
				})

				state.loading = false
			} catch (err) {
				alert.pushAlert({
					type: 'danger',
					text: `${t('Items.errors.get')}: ${err.message}`
				})
			}
		}

		getInventory()

		return {
			...toRefs(state),
			offer,
			sell,
			withdraw,
			t
		}
	}
})
