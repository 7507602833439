<template>
	<div class="profile-items" :class="{ loading }">
		<template v-if="!loading">
			<div class="items-alert" v-if="items.length">
				<img :src="require('../../assets/icons/items_alert.svg')" class="alert-icon" />
				<div class="alert-text">{{ t('Items.alert') }}</div>
				<ButtonShop class="top-shop-btn" />
			</div>
			<div class="items-scroll">
				<transition-group tag="div" class="items-wrap" appear>
					<Item
						class="item-wrap"
						v-for="(item, i) in items"
						:style="{ '--delay': `${i * 75}ms` }"
						:key="item.id"
						:id="item.id"
						:icon="item.item.icon"
						:type="item.item.type"
						:name="item.item.name"
						:price="item.item.priceUsd"
						:color="item.item.color"
						:volume="
							item.buyPriceUsd && item.item.priceUsd > item.buyPriceUsd
								? 0
								: item.item.marketVolume || 0
						"
						:stattrak="item.item.stattrak"
						:exterior="item.item.exterior"
						:hoverable="false"
						:noHover="true"
					>
						<template #overlay>
							<ItemOverlay
								:withdrawBtn="!item.gamesCount"
								:error="item.withdraw ? item.withdraw.errorMessage : null"
								:status="item.status"
								:timerEndsAt="item.timerEndsAt"
								@sell="sell(item.id)"
								@withdraw="withdraw(item.id)"
								@offer="offer(item.id)"
							/>
						</template>
					</Item>
				</transition-group>
			</div>
			<div class="items-placeholder" v-if="!items.length">
				<div class="placeholder-content">
					{{ t('Items.placeholder') }}
					<br />
					<ButtonShop class="button-shop-wrap success-dark" />
				</div>
			</div>
		</template>
		<Spinner width="64px" height="64px" class="spinner-wrap" v-if="loading" />
	</div>
</template>
<script lang="ts">
import Item from '../../components/InventoryItem/index.vue'
import ItemOverlay from '../../components/InventoryItemOverlay/index.vue'
import Spinner from '../../components/Spinner/index.vue'
import ButtonShop from '../../components/ButtonShop/index.vue'
import { defineComponent, reactive, toRefs } from 'vue'
import { useUserID } from '@/composables/getAuthToken'
import { useSubscription } from '../../composables/useSubscription'
import { useAPI } from '@/composables/api'
import { useI18n } from 'vue-i18n'
import { useAlertStore } from '@/store/alert'

export default defineComponent({
	components: {
		Item,
		ItemOverlay,
		Spinner,
		ButtonShop
	},
	emits: ['tradelink-error'],
	setup(_, { emit }) {
		const { t } = useI18n()
		const api = useAPI()
		const alert = useAlertStore()
		const state = reactive({
			items: [] as any[],
			loading: true
		})

		const setItemMeta = (id: number, meta: any) => {
			for (let i = 0; i < state.items.length; i++) {
				if (state.items[i].id === id) {
					for (const key in meta) {
						state.items[i][key] = meta[key]
					}
					break
				}
			}
		}

		const setItemStatus = (id: number, status: any) => {
			setItemMeta(id, { status })
		}

		const getItemStatus = (withdraw: any) => {
			if (withdraw) {
				if (withdraw.status === 'PENDING') {
					return 'BUYING'
				} else if (withdraw.status === 'AWAITING_SELLER') {
					return 'WAITING_SELLER'
				} else if (withdraw.status === 'AWAITING_BUYER') {
					return 'READY'
				} else if (withdraw.status === 'COMPLETED') {
					return 'COMPLETED'
				} else if (withdraw.status === 'ERROR') {
					return 'ERROR'
				}
			}

			return 'IDLE'
		}

		const getTimer = (withdraw: any) => {
			if (withdraw) {
				if (withdraw.status === 'AWAITING_SELLER' && withdraw.sellerDeclinedAt) {
					return new Date(withdraw.sellerDeclinedAt)
				} else if (withdraw.status === 'AWAITING_BUYER' && withdraw.buyerDeclinedAt) {
					return new Date(withdraw.buyerDeclinedAt)
				}
			}

			return null
		}

		const offer = (id: number) => {
			for (let i = 0; i < state.items.length; i++) {
				if (state.items[i].id === id) {
					window.open(
						`https://steamcommunity.com/tradeoffer/${state.items[i].withdraw.steamTradeId}`,
						'_blank'
					)
					break
				}
			}
		}

		const userID = useUserID()

		if (userID) {
			useSubscription<any>(`withdraw#${userID}`, ({ data: withdraw, event }) => {
				const status = getItemStatus(withdraw)
				const timerEndsAt = getTimer(withdraw)

				setItemMeta(withdraw.inventoryId!, { status, withdraw, timerEndsAt })

				if (withdraw.status === 'AWAITING_BUYER') {
					alert.pushAlert({
						type: 'trade',
						text: t('AppFrame.withdrawAlert'),
						meta: { tradeID: withdraw.steamTradeId }
					})
				}
			})
		}

		const sell = async (id: number) => {
			setItemStatus(id, 'SELLING')

			try {
				await api({
					path: '/shop/sell',
					method: 'POST',
					body: {
						inventoryIds: [id]
					}
				})

				setItemStatus(id, 'SOLD')
			} catch (err) {
				setItemStatus(id, 'IDLE')
				alert.pushAlert({
					type: 'danger',
					text: `${t('Items.errors.sell')}: ${err.message}`
				})
			}
		}

		const withdraw = async (id: number) => {
			setItemStatus(id, 'BUYING')

			try {
				await api({
					path: '/withdrawals/create/inventory',
					method: 'POST',
					body: {
						inventoryIds: [id]
					}
				})
			} catch (err) {
				setItemStatus(id, 'IDLE')
				alert.pushAlert({
					type: 'danger',
					text: `${t('Items.errors.withdraw')}: ${err.message}`
				})

				if (err.key && err.key === 'errors.WITHDRAW.USER_TRADELINK_INVALID') {
					emit('tradelink-error')
				}
			}
		}

		const getInventory = async () => {
			try {
				const items: any[] = await api({
					path: '/inventory/current',
					method: 'POST',
					body: {
						bonuses: false,
						withdrawals: true
					}
				})

				state.items = items.map((item, i) => {
					item.status = getItemStatus(item.withdrawal)
					item.timerEndsAt = getTimer(item.withdrawal)
					item.item.priceUsd = item.buyPriceUsd

					return item
				})

				state.loading = false
			} catch (err) {
				alert.pushAlert({
					type: 'danger',
					text: `${t('Items.errors.get')}: ${err.message}`
				})
			}
		}

		getInventory()

		return {
			...toRefs(state),
			offer,
			sell,
			withdraw,
			t
		}
	}
})
</script>
<style scoped>
.profile-items {
	display: flex;
	flex-wrap: wrap;
	overflow-x: hidden;
	overflow-y: auto;
	margin-right: -4px;
}

.profile-items:not(.loading) {
	align-content: baseline;
}

.items-alert {
	background: #1f212a;
	border-radius: 12px;
	padding: 15px 15px 15px 20px;
	display: flex;
	align-items: center;
	margin-right: 4px;
	width: 100%;
}

.alert-text {
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: #ffffff;
	margin-left: 15px;
}

.top-shop-btn {
	width: fit-content;
	margin-left: 20px;
	padding: 0 20px;
	flex-shrink: 0;
}

.items-scroll {
	display: flex;
	margin-right: 4px;
	overflow-x: hidden;
	height: fit-content;
	margin-top: 15px;
	padding-bottom: 40px;
	width: 100%;
}

.items-wrap {
	display: grid;
	flex-wrap: wrap;
	align-content: baseline;
	gap: 10px;
	width: 100%;
	grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

.items-placeholder {
	width: 100%;
	display: flex;
	flex: 1;
}

.placeholder-content {
	font-weight: normal;
	font-size: 24px;
	line-height: 29px;
	margin: auto;
	text-align: center;
	color: rgba(175, 177, 192, 0.5);
}

.button-shop-wrap {
	margin-top: 20px;
}

.spinner-wrap {
	margin: auto;
	color: rgba(175, 177, 192, 0.5);
}

.item-wrap.v-enter-active {
	--delay: 0ms;
	transition: all 750ms ease-out var(--delay);
	perspective: 1000px;
	transform: rotateY(0deg);
	transform-style: preserve-3d;
	backface-visibility: hidden;
	box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0);
}

.item-wrap.v-enter,
.item-wrap.v-leave-to {
	opacity: 0;
	transform: rotateY(180deg);
	box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.item-wrap.remove {
	animation: slideIn 0.5s ease-in-out 1s forwards;
}

@keyframes slideIn {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
		height: 0px;
	}
}

.item-wrap.remove ::v-deep .item-lock {
	animation: break 1s ease-in-out forwards;
}

@keyframes break {
	0% {
		opacity: 1;
	}
	5% {
		transform: rotate(5deg);
	}
	10% {
		transform: rotate(-7deg);
	}
	15% {
		transform: rotate(10deg);
	}
	20% {
		transform: rotate(-3deg);
	}
	25% {
		transform: rotate(15deg);
	}
	30% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(0deg);
	}
	100% {
		opacity: 0;
		transform: scale(4) rotate(0deg);
	}
}

@media (min-width: 951px) {
	.profile-items ::v-deep .inventory-item {
		background: #1f212a;
	}
}

@media (max-width: 950px) {
	.items-alert {
		background: #242731;
	}

	.items-scroll {
		margin-top: 10px;
	}

	.items-wrap {
		grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
	}
}
</style>
