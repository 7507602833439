<template>
	<BaseButton class="content-btn danger xs b-4 uc">
		<template #left>
			<img :src="require('../../assets/icons/try_again.svg')" />
		</template>
		{{ t('InventoryItemOverlay.tryAgain') }}
	</BaseButton>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseButton from '../BaseButton/index.vue'

export default defineComponent({
	components: {
		BaseButton
	},
	setup() {
		const { t } = useI18n()

		return {
			t
		}
	}
})
</script>
<style scoped>
.content-btn {
	height: 25px;
	width: 100%;
	margin-top: 5px;
}
</style>
